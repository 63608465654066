import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from "react-table";
import ReactSelect from '../../common/ReactSelect';
import ModeSelect from '../../common/ModeSelect';
import { sumBy, isEqual, maxBy, minBy, map, uniq, get } from 'lodash';
import cellHeaderHOC from '../../common/Datagrid/CellHeaderHOC';

import { vendorSettings } from './Vendor/Settings';
import { statsActions } from '../../../data/actions/stats';

import config from '../../../config/config';
import CSV from '../../../helpers/CSVExport';
import StackedBarChart from '../../widgets/charts/types/StackedBarChart';
import PieChart from '../../widgets/charts/types/PieChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import LineChart from '../../widgets/charts/types/LineChart';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import StackedBarChartWithAnnotations from '../../widgets/charts/types/StackedBarChartWithAnnotations';
import Box from '../../common/Box';
import ExportModal from '../../common/ExportModal';
import EntitySelector from '../../pages/result/EntitySelector';
import DateGroupSelector from '../../pages/result/DateGroupSelector';
import TrendDirection from '../../common/TrendDirection';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import LoadingMessage from '../../../helpers/LoadingMessage';

var chartSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var pieSvg = require('!svg-inline-loader!../../../../public/img/pie.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var chartBarSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');
var mapSvg = require('!svg-inline-loader!../../../../public/img/map.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var arrowLeftSvg = require('!svg-inline-loader!../../../../public/img/arrow-left.svg');
var arrowRightSvg = require('!svg-inline-loader!../../../../public/img/arrow-right.svg');
var xlsxSvg = require('!svg-inline-loader!../../../../public/img/xlsx.svg');

const CellHeaderTable = cellHeaderHOC(ReactTable);

class VendorsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: 'all',
            contentType: null,
            contentTypes: null,
            mode: props.defaultMode || 'doughnut',
            sort: {field: 'curr_units', dir: 'desc'},
            entityFilter: null,
            dateGroup: null
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setVendor = this.setVendor.bind(this);
        this.setContentType = this.setContentType.bind(this);
        this.setEntityFilter = this.setEntityFilter.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToCsvFull = this.exportToCsvFull.bind(this);
        this.exportToXls = this.exportToXls.bind(this);
    }
    
    componentDidMount() {
        const { getVendors, getVendorTimeseries } = this.props;
        const { mode } = this.state;
        getVendors(this.state, false, this.props.entity, this.props.id);
        if(mode == 'stacked_bar' || mode == 'timeline')
            getVendorTimeseries(this.state, false, this.props.entity, this.props.id);
        
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.filter && nextProps.filter.global){
            if(this.props.filter.global!==undefined && !isEqual(nextProps.filter.global, this.props.filter.global)) {
                const { mode } = this.state;
                this.setState({
                    vendor: 'all',
                    contentType: null,
                    contentTypes: null, 
                }, ()=>{
                    this.props.getVendors(this.state, false, this.props.entity, this.props.id);
                    if(mode == 'stacked_bar' || mode == 'timeline')
                        this.props.getVendorTimeseries(this.state, false, this.props.entity, this.props.id);

                })
                
            }                
        }
        if(nextProps.stats && nextProps.stats.vendor){
            let contentTypes = map(uniq(map(nextProps.stats.vendor.table, data=>data.content_type)), type=>({
                label: type, value: type
            })),
            contentType = contentTypes[0];
            
            contentTypes.unshift({
                label: 'All',
                value: null
            });
            if(this.state.contentTypes === null || !isEqual(this.state.contentTypes, contentTypes)) {
                this.setState({
                    contentTypes,
                    contentType
                });
            }
                
        }
    }

    
    setChartMode(mode){
        this.setState({mode}, ()=>{
            const { modeChange, getVendors, getVendorTimeseries } = this.props;
            if(mode == 'stacked_bar' || mode == 'timeline')
                getVendorTimeseries(this.state, true, this.props.entity, this.props.id);
            else
                getVendors(this.state, true, this.props.entity, this.props.id);
            if(typeof modeChange == 'function')
                modeChange(mode);
        });
    }
    
    setVendor(vendor){
        this.setState({vendor}, ()=>this.props.getVendorTimeseries(this.state, true, this.props.entity, this.props.id));
    }


    setContentType(contentType){
        const { mode } = this.state;
        this.setState({contentType, vendor: 'all'}, ()=>{
            if(mode == 'stacked_bar' || mode == 'timeline')
                this.props.getVendorTimeseries(this.state, true, this.props.entity, this.props.id);
            else
                this.props.getVendors(this.state, true, this.props.entity, this.props.id);
        });
    }
    
    setEntityFilter(entityFilter){
        this.setState({entityFilter}, ()=>this.props.getVendors(this.state, false, this.props.entity, this.props.id));
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>this.props.getVendorTimeseries(this.state, false, this.props.entity, this.props.id));
    }    
    
    exportToCsv(){
        const filename = CSV.CSVHeader('top_platforms', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.stats.vendor.table, {filename}, 'top_platforms');
    }
    
    exportToCsvFull(){
        this.props.getVendors(this.state, false, this.props.entity, this.props.id, true).then(()=>{
            const filename = CSV.CSVHeader('top_platforms', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle); 
            return CSV.CSVExport(this.props.stats.vendor.table, {filename}, 'top_platforms');            
        })
    }

    
    exportToXls(){
        this.props.getVendorTimeseries(this.state, false, this.props.entity, this.props.id).then(timeseries=>{
            const filename = CSV.CSVHeader('top_platforms', this.state.sort.field, this.props.filter.global);
            let data = timeseries;
            data = CSV.expandTimeline(data, 'stms_by_date');
            return CSV.XLSExport(data, {filename}, 'top_platforms');            
        })
    }

    renderExportToCsv() {
        return (
            <ExportModal exportTotals={this.exportToCsv} exportAdvanced={this.exportToCsvFull} exportBreakdown={this.exportToXls} loading={this.props.stats.vendorLoading || this.props.stats.vendorTimeseriesLoading} />
        )
    }
    
    renderToolbar(data){
        let toolbar = [],
            contentTypes = this.state.contentTypes || [];
        // for (let mode in modes) {
        //     toolbar.push(<a key={mode}><i className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode({mode})}></i></a>);                
        // }
        const options = [
            {icon: chartBarSvg, value: 'stacked_bar', label: "Bar Chart"},
            {icon: lineSvg, value: 'timeline', label: "Line Chart"},
            {icon: tableSvg, value: 'table', label: "Table"},
            // {icon: pieSvg, value: 'pie', label: "Pie Chart"},
            {icon: pieSvg, value: 'doughnut', label: "Donut Chart"},
        ];
        
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper">
        <div className="ibox-icon-holder mode-select">
            <ModeSelect options={options} selected={this.state.mode} onChange={this.setChartMode} isSearchable={ false } />
        </div>
        <div className="ibox-actions">
            <ReactSelect value={this.state.contentType} options={contentTypes} onChange={this.setContentType} className="single-select"/>
            {/* <ExportModal exportTotals={this.exportToCsv} exportBreakdown={this.exportToXls} /> */}
        </div>
        </div>)
        return toolbar;
    }

    renderVendors(vendorsObj){
        if(!vendorsObj)
            return null;
        
        let vendors = {'all': 'All'};
        for(let vendor of vendorsObj.vendors){
            vendors[vendor] = vendor;
        }
        const vendorsArray = Object.keys(vendors),
            currentVendorIndex = vendorsArray.indexOf(this.state.vendor),
            nextVendorIndex = currentVendorIndex+1,
            prevVendorIndex = currentVendorIndex-1,
            prevVendor = vendorsArray[prevVendorIndex < 0 ? vendorsArray.length-1 : prevVendorIndex],
            nextVendor = vendorsArray[nextVendorIndex > vendorsArray.length-1 ? 0 : nextVendorIndex];
        return <div className="chart-all-holder"> 
            <span className="chart-all-text">{vendors[this.state.vendor]}</span>
            <a className="angle-button angle-button--left" onClick={()=>this.setVendor(prevVendor)} dangerouslySetInnerHTML={{__html: arrowLeftSvg}}></a>
            <a className="angle-button angle-button--right" onClick={()=>this.setVendor(nextVendor)} dangerouslySetInnerHTML={{__html: arrowRightSvg}}></a>
        </div>
    }
    
    renderTrend(value) {
        const growth = value >= 0 ? 'an increase' : 'a decrease';
        return `${growth} of ${convertToThousands(Math.abs(value))}`;
    }

    renderTrends(data){
        if(!data || !data.length || !this.state.contentType)
            return null;
        if(this.state.contentType.value)
            data = data.filter(item => item.content_type == this.state.contentType.value);
        
        const topVendor = maxBy(data, 'growth'),
            lowVendor = minBy(data, 'growth'),
            totalPlays = sumBy(data, 'total'),
            showLowVendor = lowVendor && !isEqual(topVendor, lowVendor);
        return <div>
            <h3 className="content-title lowercase">{convertToThousands(totalPlays)} plays</h3>
            {/*<h4 className="content-subtitle">Total plays</h4>*/}
            
            {topVendor && <TrendDirection direction="up">
                <strong>{topVendor.vendor}</strong> shows strongest growth with {this.renderTrend(topVendor.growth)} plays      
            </TrendDirection>}
            {showLowVendor && <TrendDirection direction="down">
                <strong>{lowVendor.vendor}</strong> was your least growing vendor with {this.renderTrend(lowVendor.growth)} plays
            </TrendDirection>}  
                
        </div>;                                
    }
    
    renderChartActions(){
        const { mode } = this.state;
        return <React.Fragment>
            <EntitySelector entityChange={this.setEntityFilter} />        
            {(mode == 'stacked_bar' || mode == 'timeline') && <DateGroupSelector dateGroupChange={this.setDateGroup}/> }
        </React.Fragment>
    }

    
    render() {
        
        const modes = {
            'stacked_bar': 'chart-bar',
            'doughnut': 'chart-pie',
            'timeline': 'chart-line'
        },
        trends = config.showTrends;
      
        let tableData = [];
        if(this.props.stats.vendor) {
            tableData = this.props.stats.vendor.table;
            if(this.state.contentType && this.state.contentType.value) {
                tableData = tableData.filter(row => row.content_type == this.state.contentType.value)
            }
                
        }
        
        let { shadowChartProps } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }

        let milestonesItems = get(this.props.milestones, 'entities.mixed.tableItems', [])
        
        return  <Box title="Platforms" toolbar={this.renderToolbar(modes)} chartActions={this.renderChartActions()} loadingMessage={LoadingMessage('overview')} spinnerEnabled={this.props.stats.vendorLoading || this.props.stats.vendorTimeseriesLoading} exportToCsv={this.renderExportToCsv()}>
            
                {this.props.stats.vendor && <div className="row">
                    {trends && <div className="col-xs-12 col-sm-4">
                        {this.renderTrends(this.props.stats.vendor.data)}
                    </div>}
                    <div className={`col-xs-12 col-sm-${trends?8:12}`}>
                        <div>
                            
                            {this.state.mode == 'stacked_bar' && <div>
                                <div className="chart-actions">
                                    {this.renderVendors(this.props.stats.vendorTimeseries)}
                                </div>
                                <StackedBarChartWithAnnotations milestones={milestonesItems}  data={this.props.stats.vendorTimeseries} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} period={this.state.period} {...shadowChartProps} />
                    		</div>}
                            {this.state.mode == 'doughnut' && <div className="chart-block"><div className="chart-block-inner">
                                {/* <PieChart data={this.props.stats.vendor.chart}  /></div></div> } */}
                                <DoughnutChart data={this.props.stats.vendor.chart} {...shadowChartProps}  /></div></div> }
                            {this.state.mode == 'timeline' &&  <div className="chart-block"><div className="chart-block-inner">
                                <div className="chart-actions">
                                    {this.renderVendors(this.props.stats.vendorTimeseries)}
                                </div>
                                {/* <LineChart data={this.props.stats.vendor.timeseries} {...shadowChartProps}  /> */}
                                <LineChartWithAnnotations milestones={milestonesItems} data={this.props.stats.vendorTimeseries} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} period={this.state.period} shadowChartProps={shadowChartProps} />
                            </div></div>}
                            {this.state.mode=='table' && <div className="vendor-table vendor-table--single custom-scroll"><CellHeaderTable
                                className="simple-table"
                                    data={tableData}
                                    columns={vendorSettings.settingsForTable()}
                                    showPagination={false}
                                    defaultPageSize={tableData.length}
                                    noDataText='No results found. Please check your filters.'
                            /></div>}
                            
                        </div>
                    </div>
                </div>}
            </Box> 
    }

}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter,
        milestones: state.milestones
    } 
}

function mapDispatchToProps(dispatch, ownProps){
    return {
        getVendors: (params, cache, entity, id, advanced = false)=> {
            let { entityFilter } = params;
            if(!entityFilter)
                entityFilter = ownProps.filter;
            return dispatch(statsActions.getVendorStats(params, cache, entity, id, entityFilter, advanced));
        },
        getVendorTimeseries: (params, cache, entity, id)=> {
            let { entityFilter } = params;
            if(!entityFilter)
                entityFilter = ownProps.filter;
            return dispatch(statsActions.getVendorTimeseries(params, cache, entity, id, entityFilter));
        }
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VendorsWidget)