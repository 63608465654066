import React, { Component } from 'react';
import { connect } from 'react-redux';
import { find, map, capitalize } from 'lodash';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import {Helmet} from 'react-helmet';

import { pdf as PDFRenderer }from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import Image from '../../components/common/PDF/Image';
import { projectsActions } from '../../data/actions/projects';
import { productsActions } from '../../data/actions/products';
import { tracksActions } from '../../data/actions/tracks';
import Box from '../../components/common/Box';
import Spinner from '../../components/common/Spinner';
import EntityCard from '../../components/common/EntityCard';
import ReactSelect from '../../components/common/ReactSelect';
import Streams from '../../components/pages/audience/Streams';
import Demographics from '../../components/pages/audience/Demographics';
import EntityMilestones from '../../components/pages/audience/EntityMilestones';
import Milestones from '../../components/pages/audience/Milestones';
import Sources from '../../components/pages/audience/Sources';
import Territory from '../../components/pages/audience/Territory';
import TopPlaylists from '../../components/pages/audience/TopPlaylists';
import RepeatListeners from '../../components/pages/audience/RepeatListeners';
import SharedChart from '../../components/pages/trackComparison/SharedChart';
import DemographicsStats from '../../components/pages/trackComparison/Demographics';
import VendorsStats from '../../components/pages/trackComparison/Vendors';
import SourcesStats from '../../components/pages/trackComparison/Sources';
import AlbumArtistStats from '../../components/pages/trackComparison/AlbumArtist';
import TerritoriesStats from '../../components/pages/trackComparison/Territories';
import TracksInProduct from '../../components/pages/trackComparison/TracksInProduct'
import CSV from '../../helpers/CSVExport';
import PDF from '../../helpers/PDFExport';
import VendorLogo from "../../components/common/VendorLogo";
import SharePage from '../../components/pages/sharedPages/SharePage';
import PDFDocument from '../../components/pages/trackComparison/PDF';
import { dspLogos } from "../../components/common/Stats/utils";
import vendors from "../../components/widgets/filter/enums/vendors";
import {Widget, WithWidgetManager} from '../../components/widgets/manager';
import { milestonesActions } from '../../data/actions/milestones';


const downloadSvg = require('!svg-inline-loader!../../../public/img/download.svg');
const pdfSvg = require('!svg-inline-loader!../../../public/img/pdf.svg');

class TrackComparisonDetails extends Component {
    typeOrder = ['Stream', 'Video', 'Radio', 'Download', 'Social Creates', 'Social Views'];
    
    constructor(props) {
        super(props);
        this.state = {
            ids: null,
            weeks: null,
            metadata: [{}, {}],
            sourceType: null
        };
        this.getTrackMetadata = this.getTrackMetadata.bind(this);
        this.setSourceType = this.setSourceType.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.exportToPdf = this.exportToPdf.bind(this);
        this.renderVendorLogos = this.renderVendorLogos.bind(this);
    }
    
    getTrackMetadata(ids) {
        //const { match, dispatch } = this.props;
        return this.props.getReleaseDates(ids)
        .then(metadata => {
            this.setState({
                metadata
            });
            return metadata;    
        });
    }

    componentDidMount() {
        const { match, territories, vendors } = this.props;
        
        this.setState({
            ids: match.params.ids,
            weeks: match.params.weeks
        });
        this.getTrackMetadata(match.params.ids)
        .then((metadata)=>{
            const ids = map(metadata, 'code').join(',');
            const releaseDates = map(metadata, 'release_date').join(',');
            this.props.compare(ids, match.params.weeks, releaseDates, territories, vendors);

            metadata.map(item => {
                this.props.getEntitiesMilestones(item.id)
            })
        });
    }
    
    componentWillReceiveProps(props) {
        const { match} = props;
        if((this.state.ids && this.state.ids !== match.params.ids) 
            || (this.state.weeks && this.state.weeks !== match.params.weeks)
            || (props.territories !== this.props.territories)
            || (props.vendors !== this.props.vendors)) {
            this.setState({
                ids: match.params.ids,
                weeks: match.params.weeks
            }, ()=>{
                this.getTrackMetadata(match.params.ids)
                .then((metadata)=>{
                    const ids = map(metadata, 'code').join(',');
                    const releaseDates = map(metadata, 'release_date').join(',');
                    this.props.compare(ids, match.params.weeks, releaseDates, props.territories, props.vendors);
                    metadata.map(item => this.props.getEntitiesMilestones(item.id));
                });
            });
        }
    }

    
    setParentEntity(entity) {
        this.setState({
            title: entity.name
        })
    }
    
    /*
   
    componentWillReceiveProps(nextProps){
        
        const { match } = nextProps;
    }
    
    */
    
    exportStreamsListeners({labels, datasets}) {
        let result = [];
        for(let index in labels) {
            result.push({
                date: labels[index],
                total_streams: datasets[0].data[index],
                unique_listeners: datasets[1].data[index]
            })
        }
        return result;
    }
    
    isDataReady() {
        const { tracks, match } = this.props;
        const entity = match.params.entity;
        
        const dataLoadingFlags = [
            tracks.comparisonLoading, 
            tracks.demographicsComparisonLoading, 
            tracks.vendorsComparisonLoading,  
            tracks.territoriesComparisonLoading
        ];
        let ready = true;
        for(let flag of dataLoadingFlags) {
            if(flag) {
                ready = false;
                break;
            }
        }
        return ready;
    }

    exportToCsv() {
        const {
            tracks,
            match
        } = this.props;
        
        const [trackID0, trackID1] = match.params.ids.split(','),
            entity = match.params.entity,
            sources = this.props[entity];
        
        let filterKey = '';
        switch(entity) {
            case 'projects':
                filterKey = 'identifier';
                break;
                
            case 'products':
                filterKey = 'upc';
                break;
                
            default:
                filterKey = 'isrc';
        }
        
        let data = [
            {[`streams - ${trackID0}`] : sources.comparison.data.filter(item=>item[filterKey] == trackID0)},
            {[`streams - ${trackID1}`] : sources.comparison.data.filter(item=>item[filterKey]== trackID1)},
            {[`age-gender - ${trackID0}`] : sources.demographicsComparison[trackID0] ? sources.demographicsComparison[trackID0].data : []},
            {[`age-gender - ${trackID1}`] : sources.demographicsComparison[trackID1] ? sources.demographicsComparison[trackID1].data : []},          
            {[`vendors - ${trackID0}`] : sources.vendorsComparison[trackID0] ? sources.vendorsComparison[trackID0].data : []},
            {[`vendors - ${trackID1}`] : sources.vendorsComparison[trackID1] ? sources.vendorsComparison[trackID1].data : []},
            {[`territories - ${trackID0}`] : sources.territoriesComparison[trackID0] ? sources.territoriesComparison[trackID0] : []},
            {[`territories - ${trackID1}`] : sources.territoriesComparison[trackID1] ? sources.territoriesComparison[trackID1] : []},
        ];
        
        if(entity == 'products') {
            data.push({[`tracks - ${trackID0}`] : tracks.productTracksComparison[trackID0] ? tracks.productTracksComparison[trackID0].data : []},)
            data.push({[`tracks - ${trackID1}`] : tracks.productTracksComparison[trackID1] ? tracks.productTracksComparison[trackID1].data : []},)

        }

        const title = entity.replace(/\s$/, '') + '_comparison';
        const filename = CSV.CSVHeader(title, match.params.ids);
        CSV.CSVBulkExport(data, filename);
      }
    
    getPdfData() {
        const { entity, ids } = this.props.match.params,
            { metadata, weeks } = this.state,
            reportTitle = `${capitalize(entity.replace(/\s$/, ''))} Comparison (${weeks} weeks)`,
            territoryData = this.props[entity],
            productTracks = this.props.tracks.productTracksComparison;
        
        const trackIDs = ids.split(',');
            
        const imageIDs = [
            'track_comparison_streams',
            'track_comparison_listeners',
            'track_comparison_ratio',
            'track_comparison_active',
            'track_comparison_skipped',
            'track_comparison_demographics_0', 
            'track_comparison_demographics_1',
            'track_comparison_vendors_0',
            'track_comparison_vendors_1',
            'track_comparison_sources_0',
            'track_comparison_sources_1',
            'track_comparison_artist_0',
            'track_comparison_artist_1',
            'tracks_in_product_0',
            'tracks_in_product_1',
            'tracks_in_product_0_curr_units',
            'tracks_in_product_0_skipped_ratio',
            'tracks_in_product_0_collection_ratio',
            'tracks_in_product_0_engagement_ratio',
            'tracks_in_product_0_playlist_ratio',
            'tracks_in_product_1_skipped_ratio',
            'tracks_in_product_1_curr_units',
            'tracks_in_product_1_collection_ratio',
            'tracks_in_product_1_engagement_ratio',
            'tracks_in_product_1_playlist_ratio'
        ];
        let images = {};
        for (let imageID of imageIDs){
            let image = document.getElementById(imageID);
            if(image)
                images[imageID] = image.toDataURL("image/jpeg,1.0"); 
        }
        return { reportTitle, metadata, images, tables: territoryData.territoriesComparison, entities: metadata, ids: trackIDs, productTracks: productTracks };
    }
    
    exportToPdf() {
        const data = PDFRenderer(<PDFDocument data={this.getPdfData()} />);
        const { entity, ids } = this.props.match.params;
        const title = entity.replace(/\s$/, '') + '_comparison';
        const filename = CSV.CSVHeader(title, ids);


        data.toBlob()
        .then(blob=>{
            const fileURL = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${filename}.pdf`;
            link.click();
        });
    }
    
    setSourceType({value}) {
        this.setState({
            sourceType: value
        })
    }
    
    renderSourceTypes() {
        let options = [{
            label: 'All',
            value: null
        }];
        for(let sourceType of this.typeOrder) {
            options.push({label: sourceType, value: sourceType})
        }
        const value = find(options, (option)=>option.value == this.state.sourceType);
        return <ReactSelect options={options} value={value} onChange={this.setSourceType} /> 
    }
    
    renderVendorLogos(chartType) {
        let logoData = dspLogos[chartType].data;

        if(this.props.vendors) {
            let vendorIDs = this.props.vendors.split(','),
                vendorNames = [];
            for(let vendorID of vendorIDs) {
                let vendorData = find(vendors, v => v.vend_id == vendorID);
                if(vendorData) {
                    vendorNames.push(vendorData.value)
                }
            };
            if(vendorNames.length) {
                logoData = logoData.filter(logo=>vendorNames.includes(logo));
            }
        }
        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    
    render() {
        const { ids, weeks, entity } = this.props.match.params;
        const trackIDs = ids.split(',');
        const { comparison, comparisonLoading } = this.props[entity];
        const { metadata } = this.state;
        const { territories, vendors, sharedMode = false, widgetProps, milestones } = this.props;
        if(comparisonLoading || !comparison)
            return <Spinner enabled={true} />

        // const milestonesArray = metadata.map(item => milestones.comparisonMilestones[item.id])
        
        return <div className="">
            {this.isDataReady() && <div className="row download-page">
                <div className="download-item">
                    {!sharedMode && <SharePage module="track_comparison" entity={entity} id={ids} params={weeks} exportToCsv={this.exportToCsv} exportToPdf={this.exportToPdf}  />}
                </div>
                {sharedMode && <React.Fragment>
                <div className="download-item">
                    <span>Export</span>
                    <a key="download" title="Download entire page" onClick={this.exportToCsv} className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
                </div>

                <div className="download-item">
                    <span>PDF</span>
                    <a key="pdf" title="Download PDF" onClick={this.exportToPdf} className="download-link" dangerouslySetInnerHTML={{__html: pdfSvg}} />
                </div>
                </React.Fragment>}
            </div>}
                    {/*this.isDataReady() && <PDFViewer><PDFDocument data={this.getPdfData()} /> </PDFViewer>*/}                    
            <div className="row">                
                <div className="col-xs-12">
                    <Widget widgetID="streams_compare" widgetProps={widgetProps}>
                        <SharedChart weeks={weeks} entity={entity} data={comparison} metric="streams" metadata={metadata} chartID="track_comparison_streams" shadowChart={true} milestones={milestones} /> 

                    </Widget>
                </div>
            </div>        
            <div className="row">
                <div className="col-xs-12 col-sm-6">
                    <Widget widgetID="listeners_compare" widgetProps={widgetProps}>
                        <SharedChart weeks={weeks} entity={entity} data={comparison} metric="listeners" metadata={metadata} chartID="track_comparison_listeners" shadowChart={true}  milestones={milestones} /> 
                    </Widget>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Widget widgetID="ratio_compare" widgetProps={widgetProps}>

                        <SharedChart weeks={weeks} entity={entity} data={comparison} metric="ratio" metadata={metadata} chartID="track_comparison_ratio" shadowChart={true} milestones={milestones} /> 

                    </Widget>
                </div>
            </div>                
            <div className="row">                
                <div className="col-xs-12 col-sm-6">
                    <Widget widgetID="active_compare" widgetProps={widgetProps}>
                        <SharedChart weeks={weeks} entity={entity} data={comparison} metric="active" metadata={metadata} chartID="track_comparison_active" shadowChart={true} milestones={milestones} /> 
                    </Widget>
                </div>
                <div className="col-xs-12 col-sm-6">
                    <Widget widgetID="skipped_compare" widgetProps={widgetProps}>

                        <SharedChart weeks={weeks} entity={entity} data={comparison} metric="skipped" metadata={metadata} chartID="track_comparison_skipped" shadowChart={true} milestones={milestones} /> 

                    </Widget>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Age and Gender</h2>
                    {this.renderVendorLogos('age')}
                </div>
                
                    <div className="compare-entity-holder">
                        <div className="compare-entity-item">
                        <Widget widgetID="demographics_compare" widgetProps={widgetProps}>
                            <DemographicsStats entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]} territories={territories} vendors={vendors} chartID="track_comparison_demographics_0" shadowChart={true} /> 
                        </Widget>
                        </div>
                        <div className="compare-entity-item">
                        <Widget widgetID="demographics_compare" widgetProps={widgetProps}>
                            <DemographicsStats entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]} territories={territories} vendors={vendors} chartID="track_comparison_demographics_1" shadowChart={true} /> 
                        </Widget>
                        </div>
                    </div>
            </div>                

            <div className="row">
                <div className="col-xs-12">
                    <div className='ibox-title'>
                        <h2 className="ibox-title__title">
                           <span className="title-text">Compare: Platforms</span>
                            <div className="track-select-container">
                                {this.renderSourceTypes()}
                            </div>
                        </h2>
                    </div>
                </div>
                
                    <div className="compare-entity-holder">
                        <div className="compare-entity-item">
                        <Widget widgetID="vendors_compare" widgetProps={widgetProps}>
                            <VendorsStats entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]} source={this.state.sourceType}  territories={territories} vendors={vendors} chartID="track_comparison_vendors_0" shadowChart={true} /> 
                        </Widget>
                        </div>
                        <div className="compare-entity-item">
                        <Widget widgetID="vendors_compare" widgetProps={widgetProps}>
                            <VendorsStats entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]} source={this.state.sourceType}  territories={territories} vendors={vendors} chartID="track_comparison_vendors_1" shadowChart={true} /> 
                        </Widget>
                        </div>
                    </div>
            </div>                
            
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Sources</h2>
                    {this.renderVendorLogos('sources')}
                </div>
                
                    <div className="col-xs-12">
                        <div className="compare-entity-holder">
                            <div className="compare-entity-item">
                            <Widget widgetID="sources_compare" widgetProps={widgetProps}>
                                <SourcesStats entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} chartID="track_comparison_sources_0" shadowChart={true} /> 
                            </Widget>
                            </div>
                            <div className="compare-entity-item">
                            <Widget widgetID="sources_compare" widgetProps={widgetProps}>
                                <SourcesStats entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} chartID="track_comparison_sources_1" shadowChart={true} /> 
                            </Widget>
                            </div>
                        </div>
                    </div>
            </div>    
            
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Territories</h2>
                    {this.renderVendorLogos('territories')}
                </div>
                
                    <div className="col-xs-12">
                        <div className="compare-entity-holder">
                            <div className="compare-entity-item">
                            <Widget widgetID="territories_compare" widgetProps={widgetProps}>
                                <TerritoriesStats entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} /> 
                            </Widget>
                            </div>
                            <div className="compare-entity-item">
                                <TerritoriesStats entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} /> 
                            </div>
                        </div>
                    </div>
            </div>            
            
            <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Compare: Artist Catalog</h2>
                </div>
                
                    <div className="col-xs-12">
                        <div className="compare-entity-holder">
                            <div className="compare-entity-item">
                            <Widget widgetID="album_artist_compare" widgetProps={widgetProps}>
                                <AlbumArtistStats entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} chartID="track_comparison_artist_0" /> 
                            </Widget>
                            </div>
                            <div className="compare-entity-item">
                            <Widget widgetID="album_artist_compare" widgetProps={widgetProps}>
                                <AlbumArtistStats entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} chartID="track_comparison_artist_1"  /> 
                            </Widget>
                            </div>
                        </div>
                    </div>
            </div>                              

            { entity == 'products' && <div className="row">
                <div className="col-xs-12">
                    <h2 className="ibox-title__title">Track Analysis</h2>
                    {/*this.renderVendorLogos('territories')*/}
                </div>
                
                    <React.Fragment>
                    <div className="col-xs-12">
                    <Widget widgetID="tracks_in_product_compare" widgetProps={widgetProps}>
                        <TracksInProduct entity={entity} id={trackIDs[0]} weeks={weeks} metadata={metadata[0]}  territories={territories} vendors={vendors} chartID="tracks_in_product_0" /> 
                    </Widget>
                    </div>
                    <div className="col-xs-12">
                    <Widget widgetID="tracks_in_product_compare" widgetProps={widgetProps}>
                        <TracksInProduct entity={entity} id={trackIDs[1]} weeks={weeks} metadata={metadata[1]}  territories={territories} vendors={vendors} chartID="tracks_in_product_1" /> 
                    </Widget>
                    </div>
                    </React.Fragment>
            </div> }             
        </div>
    }
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        audience: state.audience,
        playlists: state.playlists,
        tracks: state.tracks,
        products: state.products,
        projects: state.projects,
        milestones: state.milestones
    }
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        getReleaseDates: (ids) => {
            switch(ownProps.match.params.entity) {
                case 'projects':
                    return dispatch(projectsActions.getProjectsReleaseDates(ids))
                    break;
                case 'products':
                    return dispatch(productsActions.getProductsReleaseDates(ids))
                    break;
                case 'tracks':
                    return dispatch(tracksActions.getTracksReleaseDates(ids))
                    break;

            }
        },
        compare: (ids, weeks, releaseDates, territories, vendors) => {
            switch(ownProps.match.params.entity) {
                case 'projects':
                    return dispatch(projectsActions.compareProjects(ids, weeks, releaseDates, territories, vendors));
                    break;
                
                case 'products':
                    return dispatch(productsActions.compareProducts(ids, weeks, releaseDates, territories, vendors));
                    break;
                case 'tracks':
                    return dispatch(tracksActions.compareTracks(ids, weeks, releaseDates, territories, vendors));
                    break;

            }
        },
        getEntitiesMilestones: (id) => {
            switch(ownProps.match.params.entity) {
                case 'products':
                    return dispatch(milestonesActions.getComparisonMilestones('products', id));
                    break;
                case 'tracks':
                    return dispatch(milestonesActions.getComparisonMilestones('tracks', id));
                    break;

            }
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithWidgetManager(TrackComparisonDetails, 'track_comparison_details'))