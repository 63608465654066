import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Landing from '../../views/Landing';
import HomeView from '../../views/Home';
import MainView from '../../views/Main';
import CatalogView from '../../views/Catalog';
//import AdminView from '../../views/Admin';
import PlaylistSearchView from '../../views/Playlists/Search';
import PlaylistOverviewView from '../../views/Playlists/Overview';
import PlaylistDetailsView from '../../views/Playlists/Details';
import PlaylistsFilterView from '../../views/Playlists/PlaylistsFilter';
import PlaylistsFilterDetailsView from '../../views/Playlists/PlaylistsFilterDetails';
import ImprintDetailsView from '../../views/Imprint/Details';
import ArtistProfileView from '../../views/Artist/Profile';
import ProductDetailsView from '../../views/Product/Details';
import ProjectDetailsView from '../../views/Project/Details';
import TrackDetailsView from '../../views/Track/Details';
import RouteLogger from '../../helpers/RouteLogger';
//import HeartBeatsView from '../../views/Heartbeats';
import HeartBeatsView from '../../views/PagedHeartbeats';
import HeartAttacksView from '../../views/HeartAttacks';
import NewReleasesView from '../../views/NewReleases';
import TerritoryAnalysis from '../../views/TerritoryAnalysis/TerritoryAnalysis';
import { NotificationSettings } from '../../views/NotificationSettings/';
import NotificationSettingsNew from '../../views/Notifications/Settings';
import CampaignOverviewView from '../../views/Campaign/Overview';
import AudienceOverviewView from '../../views/Audience/Overview';
import TrackComparisonSearchView from '../../views/TrackComparison/Search';
import API from '../../views/API';
import UserProfile from '../../views/User/Profile';
import UserFavorites from '../../views/User/Favorites';
import UserSharedPages from '../../views/User/SharedPages';
import UserEntitiesFilter from '../../views/User/EntitiesFilter';
import EntityFilterDetails from '../../views/User/EntityFilterDetails';
import GridView from '../../views/Grid';
import Filter from '../../views/Filter';
import FilterResult from '../../views/Result';
import FilterResultRedirect from '../../views/Result/Redirect';
import CSVView from '../../views/CSV';
import SearchResultsView from '../../views/SearchResults/List';
import SpotifyDiscoveryReportView from '../../views/SpotifyDiscovery/Report';
import SpotifyMonetizationReportView from '../../views/SpotifyMonetization/Report';
import MilestonesTypesView from '../../views/Milestones/Types';
import MissingDataView from '../../views/MissingData';
import MailingListsView from '../../views/MailingLists';
import MailingListsRecipientsView from '../../views/MailingLists/Recipients';
import TestComparison from '../../views/Test';
import WithFilter from '../../helpers/WithFilter';
const WithFilterMissingDataView = WithFilter(MissingDataView);
const WithFilterResultRedirect = WithFilter(FilterResultRedirect);

const main=(props)=>{
    return <RouteLogger>
            <Switch>
                <Route path="/landing" component={Landing}></Route>
                <Route path="/main" component={Filter}></Route>
                <Route path="/catalog/:entity" component={CatalogView}></Route>
                <Route path="/overview" component={HomeView}></Route>
                {/*<Route path="/admin" component={(AdminView)}></Route>*/}
				<Route path="/playlists/filter/:id" component={PlaylistsFilterDetailsView}></Route>
				<Route path="/playlists/filter" component={(PlaylistsFilterView)}></Route>
                <Route path="/playlists/:id/overview" component={(PlaylistOverviewView)}></Route>
                <Route path="/playlists/:id/details" component={(PlaylistDetailsView)}></Route>
                <Route path="/playlists/:entity_type?/:entity_id?" component={(PlaylistSearchView)}></Route>
                <Route path="/reporting/heartbeats/:week?/:minStreams?/:percentage?/:minTiktokCreates?/:newRelease?/:vendors?/:territories?" component={(HeartBeatsView)}></Route>
                <Route path="/reporting/nosedives/:week?/:minStreams?/:percentage?/:minTiktokCreates?/:newRelease?/:vendor?" component={(HeartAttacksView)}></Route>
                <Route path="/reporting/new-releases" component={(NewReleasesView)}></Route>
                <Route path="/reporting/spotify-discovery" component={(SpotifyDiscoveryReportView)}></Route>
                <Route path="/reporting/spotify-monetization/:dates?" component={(SpotifyMonetizationReportView)}></Route>
                <Route path="/user/notifications" component={(NotificationSettings)}></Route>
                <Route path="/user/notification-settings" component={(NotificationSettingsNew)}></Route>
                <Route path="/imprints/:id" component={(ImprintDetailsView)}></Route>
                <Route path="/artists/:id" component={(ArtistProfileView)}></Route>
                <Route path="/products/:id" component={(ProductDetailsView)}></Route>
                <Route path="/projects/:id" component={(ProjectDetailsView)}></Route>
                <Route path="/tracks/:id" component={(TrackDetailsView)}></Route>
                <Route path="/campaigns/:id?" component={(CampaignOverviewView)}></Route>
                <Route path="/audience/:entity?/:id?" component={(AudienceOverviewView)}></Route>
                <Route path="/comparison/:entity?/:ids?/:weeks?" component={(TrackComparisonSearchView)}></Route>
                <Route path="/territory-analysis" component={(TerritoryAnalysis)}></Route>
                <Route path="/user/api" component={(API)}></Route>
                <Route path="/user/profile" component={(UserProfile)}></Route>
                <Route path="/user/favorites" component={(UserFavorites)}></Route>
                <Route path="/user/templates" component={GridView}></Route>
                <Route path="/user/shared-pages" component={UserSharedPages}></Route>
                <Route path="/user/entities-filter/:id" component={EntityFilterDetails}></Route>
                <Route path="/user/entities-filter" component={UserEntitiesFilter}></Route>
                <Route path="/filter/:filter/:tab/:global" component={(WithFilterResultRedirect)}></Route>
                <Route path="/filter/:filter" component={(FilterResult)}></Route>
                <Route path="/search-results" component={(SearchResultsView)}></Route>
                <Route path="/data-status" component={(WithFilterMissingDataView)}></Route>
                <Route path="/milestones/:type?" component={(MilestonesTypesView)}></Route>
                <Route path="/data-status" component={(WithFilterMissingDataView)}></Route>
                <Route path="/user/mailing-lists/recipients/:id" component={(MailingListsRecipientsView)}></Route>                
                <Route path="/user/mailing-lists" component={(MailingListsView)}></Route>
                <Route path="/test-comparison" component={(TestComparison)}></Route>
                <Redirect from="/" to="/landing" />
            </Switch>
        </RouteLogger>
    }
export default main;
