import React, { Component } from 'react';
import { find, sumBy, map, get } from 'lodash';
import { connect } from 'react-redux';
import ReactSelect from '../../common/ReactSelect';
import DateGroupSelector from '../../pages/result/DateGroupSelector';
import config from '../../../config/config';
import { LineChart, StackedBarChart } from '../../widgets/charts/types';
import Box from '../../common/Box';
import CSV from '../../../helpers/CSVExport';
import { playlistsActions} from '../../../data/actions/playlists';
import { convertToThousands } from '../../../helpers/NumberFormatter';
import ModeSelect from '../../common/ModeSelect';
import LineChartWithAnnotations from '../../widgets/charts/types/LineChartWithAnnotations';
import StackedBarChartWithAnnotations from '../../widgets/charts/types/StackedBarChartWithAnnotations';

var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var barSvg = require('!svg-inline-loader!../../../../public/img/chart-bar.svg');

class PlaylistDistribution extends Component {

    nullElement = {value: null, label: 'All'}
    constructor(props) {
        super(props);
        this.state = {
            playlist: this.nullElement,
            view: 'bar',
            dateGroup: null
        }
        this.setView = this.setView.bind(this);
        this.setPlaylist = this.setPlaylist.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setDateGroup = this.setDateGroup.bind(this);
    }
    
    componentDidMount() {
        const { data, dispatch, filtered: extFiltered } = this.props;
        if(data && data.length) {
            const ids = map(data, 'playlist_id').filter(id=>id);
            let filtered = [{id:'ids', value:ids}];
            dispatch(playlistsActions.getTimeseriesPlaylists({sorted: 'curr_units', filtered}, null, extFiltered));
        }

    }
    
    exportToCsv (){
        const filename = CSV.CSVHeader('playlist_distribution', 'curr_units', this.props.filter.global, this.props.parentEntityTitle); 
        return CSV.CSVExport(this.props.data, {filename});        
    }
    
    setPlaylist(playlist) {
        this.setState({playlist});
    }
    
    setDateGroup(dateGroup) {
        this.setState({dateGroup}, ()=>{
            const { data, dispatch, filtered: extFiltered } = this.props;
            const ids = map(data, 'playlist_id').filter(id=>id);
            let filtered = [{id:'ids', value:ids}];
            dispatch(playlistsActions.getTimeseriesPlaylists({sorted: 'curr_units', filtered, dateGroup}, null, extFiltered));
        });
    }

    setView(view){
        this.setState({
            view
        })
    }

    
    renderToolbar(data = []){
        let playlists = [this.nullElement];
        for(let playlist of data)
            playlists.push({
                value: playlist.id,
                label: playlist.label
            });
        //onChange={this.setPlaylist}
        
        const options = [
             {icon: barSvg, value: 'bar', label: "Bar Chart"},
             {icon: lineSvg, value: 'line', label: "Line Chart"},
         ];

        
        let toolbar = [];
        toolbar.push(<div key="wrapper" className="ibox-action-wrapper single-element">
        <div className="ibox-actions mode-select">
        <ModeSelect options={options} selected={this.state.view} onChange={this.setView} isSearchable={ false } />
          {/*<ReactSelect value={this.state.playlist} options={playlists} onChange={this.setPlaylist} className="single-select"/>*/}
          <a key="download" onClick={this.exportToCsv} title="Export CSV" className="download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />
        </div>
        </div>)

        return toolbar;
    }
    
    renderTrends(data, single) {
        
        if(!data || !data.length)
            return null;
        
        const trendingPlaylist = data[0],
            total = sumBy(data, 'curr_units'),
            growth = data.units_diff > 0;
        
        return <div>
            <h2 className="content-title lowercase">{convertToThousands(total)} of streams</h2>
                {!single && <div><h4 className="content-subtitle">are from playlists</h4>
                        <div className={`description ${growth ? 'up' : 'down'}`}>
                            <span className="icon fa"></span>
                            <p><strong>{trendingPlaylist.name}</strong> was your top playlist with <span className="num lowercase">{convertToThousands(trendingPlaylist.curr_units)}</span> streams in total. </p>
                        </div>
                </div>}
                {single && <h4 className="content-subtitle"> are from <strong>{trendingPlaylist.name}</strong> playlist
                </h4>}
            </div>;                        
                
    }
    
    renderChartActions(){
        return <React.Fragment>
            <DateGroupSelector dateGroupChange={this.setDateGroup}/>        
        </React.Fragment>
    }
    
    
    
    render(){
        let { timeseries: data, timeseriesLoading } = this.props.playlists;

        const trends = config.showTrends;
        
        /*
        let chartData, singlePlaylist ;
        
        if(this.state.playlist.value!==null) {
            chartData = [find(data, {id: this.state.playlist.value})];
            singlePlaylist = true;
        }
        else {
        
            chartData = data;
            singlePlaylist = false;
        */
        
        let { shadowChartProps, milestones } = this.props;
        if(shadowChartProps){
            shadowChartProps.shadowChart = true;
        }       
        
        const ChartComponent = this.state.view == 'line' ? LineChart : StackedBarChart; 
        let milestonesItems = get(milestones, 'entities.mixed.tableItems', []);

        return <Box title="Playlist Distribution" toolbar={this.renderToolbar()} chartActions={this.renderChartActions()} spinnerEnabled={timeseriesLoading} >
            <div className="row">
                <div className={`col-xs-12 col-sm-${trends?8:12}`}>
                    <div className="chart-block">
                        {(data && !timeseriesLoading) && <React.Fragment>
                            {this.state.view == 'line' ? 
                                <LineChartWithAnnotations milestones={milestonesItems} data={data} labels={{header: 'Playlists', titleLabel: 'streams', valueLabel: 'streams'}} period={this.state.period} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range} shadowChartProps={shadowChartProps} /> : 
                                <StackedBarChartWithAnnotations milestones={milestonesItems} data={data} period={this.state.period} dateGroup={this.state.dateGroup ? this.state.dateGroup : this.props.filter.global.range}
                                labels={{header: 'Playlists', titleLabel: 'streams', valueLabel: 'streams'}}
                                shadowChartProps={shadowChartProps}  /> }
                            </React.Fragment>
                            // <ChartComponent 
                            // data={data}
                            // labels={{header: 'Playlists', titleLabel: 'streams', valueLabel: 'streams'}}
                            // {...shadowChartProps}/> 
                        }
                    </div>
                </div>
            </div>
        </Box>
    }
                        
}

function mapStateToProps(state) {
    return {
        filter: state.filter,
        playlists: state.playlists,
        milestones: state.milestones
    } 
}

export default connect(mapStateToProps)(PlaylistDistribution);