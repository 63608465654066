import { channelsActions }  from './channels';
import { artistsConstants } from '../constants/artists';
import { artistsService } from '../services/artists';
import { artistsFormatter } from '../formatters/artists';
import { find } from 'lodash';

export const artistsActions = {
    getArtists,
    getTopArtists,
    getArtistsAutocomplete,
    getArtistDetails,
    getCard,
    getStreamsStats,
    getArtistProfile,
    getFollowers,
    getProducts,
    getMetadata,
    getTimeseriesArtists,
    getArtistsSocial
};

function getArtists(params, basic) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        artistsService.getArtists(params, globalFilter, basic)
            .then(
                artists => {
                    const data = artistsFormatter.formatForTable(artists);
                    //dispatch(success(data, artists.pages));
                    return dispatch(getMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_REQUEST } }
    function success(artists, pages) { return { type: artistsConstants.GET_ARTISTS_SUCCESS, artists, pages } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_FAILURE, error } }
}


function getArtistsAutocomplete(search, limit=10) {
    return dispatch => {
        dispatch(request());
        if(!search) {
            dispatch(success([]));
            return Promise.resolve([]);
        }
        return artistsService.getArtistsAutocomplete(search)
            .then(
                artists => {
                    const data = artistsFormatter.formatAutocomplete(artists.results, ['artists']);
                    dispatch(success(data.slice(0, limit)));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_SUCCESS, artists} }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_AUTOCOMPLETE_FAILURE, error } }
}

function getTopArtists(params, useCache, filter, advanced = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        const fields = advanced ? ['*'] : ['artist_id', 'curr_units', 'prev_units', 'units_diff', 'passive', 'active', 'skipped_audio_ratio', 'skipped_video_ratio', 'completed_audio_ratio', 'completed_video_ratio', 'curr_playlists', 'prev_playlists', 'playlists_diff', 'vendors'];
        
        if(filter && !(params.filtered && params.filtered.length)) {
            let filtered = [];
//            if(filter.artists){
//                filtered.push({
//                    id: 'artists',
//                    value: filter.artists                    
//                })
//            }
//            else {
                for(let entity of Object.keys(filter)) {
                    filtered.push({
                        id: entity,
                        value: filter[entity]
                    })
                };
//            }
            params.filtered = filtered;
        }
        
        return artistsService.getTopArtists(params, globalFilter, fields)
            .then(
                artists => {
                    const data = artistsFormatter.formatTop(artists);
                    //dispatch(success(data));
                    return dispatch(getTopMetadata(data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TOP_ARTISTS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_TOP_ARTISTS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_TOP_ARTISTS_FAILURE, error } }
}


function getArtistDetails(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const artists = getState().artists.top,
            artist = find(artists, {artist_id: id});
        return Promise.resolve(artist)
            .then(
                artist => {
                    const data = artistsFormatter.formatDetails(artist.stms_by_date);
                    dispatch(success(id, data));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTIST_DETAILS_REQUEST } }
    function success(id, artist) { return { type: artistsConstants.GET_ARTIST_DETAILS_SUCCESS, id, artist } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_DETAILS_FAILURE, error } }
}

function getCard(id, socials = false, recommended = false) {
    return ( dispatch, getState ) => {
        dispatch(request());
        
        return dispatch(channelsActions.getChannelTypes())
        .then(channelTypes=> {
            return artistsService.getCard(id, socials, recommended)
            .then(
                card => {
                    card = artistsFormatter.formatCard(card);
                    let channels = [];
                    if(card.channels && card.channels.length)
                        channels = artistsFormatter.formatArtistChannels(card.channels, channelTypes);
                    dispatch(success(id, card, channels));
                    return card;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
        })
    };
    

    function request() { return { type: artistsConstants.GET_ARTISTS_CARD_REQUEST } }
    function success(id, card, channels) { return { type: artistsConstants.GET_ARTISTS_CARD_SUCCESS, id, card, channels} }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_CARD_FAILURE, error } }
}

function getStreamsStats(ids, filtered = []) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        Promise.all([artistsService.getStreamsStats(ids, filtered, globalFilter),
                     artistsService.getMetadata(ids)])                
            .then(
                ([artists, metadata]) => {
                    // const metadata = getState().artists.metadata;
                    artists = artistsFormatter.formatStreamsStats(artists, metadata.data);
                    dispatch(success(artists));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_STREAMS_STATS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_STREAMS_STATS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_STREAMS_STATS_FAILURE, error } }
}

function getArtistProfile(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global;
        artistsService.getCard(id, true, false, true)
        .then(card=>{
            artistsService.getArtistProfile(id, globalFilter)        
            .then(
                artist => {
                    artist = artistsFormatter.formatProfile(artist, card);
                    dispatch(success(id, artist));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
        })

    };

    function request() { return { type: artistsConstants.GET_ARTIST_PROFILE_REQUEST } }
    function success(id, artist) { return { type: artistsConstants.GET_ARTIST_PROFILE_SUCCESS, id, artist } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_PROFILE_FAILURE, error } }
}


function getFollowers(channels, total = false, metric = 'metric') {
    return ( dispatch, getState ) => {
        dispatch(request(metric));
        if(!channels || !channels.length){
            return dispatch(success(null));
        }
        const globalFilter = getState().filter.global;
        
        if(!channels.length)
            return dispatch(success(null));
        
        artistsService.getFollowers(channels, globalFilter)
            .then(
                followers => {
                    if(total)
                        followers = artistsFormatter.formatTotalFollowersForChart(followers, metric);
                    else
                        followers = artistsFormatter.formatFollowersForChart(followers, metric);
                    dispatch(success(followers, metric));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request(metric) { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_REQUEST, metric } }
    function success(followers, metric) { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_SUCCESS, followers, metric } }
    function failure(error) { return { type: artistsConstants.GET_ARTIST_FOLLOWERS_FAILURE, error } }
}

function getProducts(id) {
    return ( dispatch, getState ) => {
        dispatch(request());
        return artistsService.getProducts(id)
            .then(
                products => {
                    const data = artistsFormatter.formatProducts(products.data);
                    dispatch(success(data));
                    return data;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_PRODUCTS_REQUEST } }
    function success(products) { return { type: artistsConstants.GET_PRODUCTS_SUCCESS, products} }
    function failure(error) { return { type: artistsConstants.GET_PRODUCTS_FAILURE, error } }
}

function getTopMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.map(entity=>entity.id);
        return artistsService.getMetadata(ids)
            .then(
                metadata => {
                    entities = artistsFormatter.formatMetadata(entities, metadata.data);
                    dispatch(success(entities, metadata.data));
                    return entities;

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TOP_ARTISTS_REQUEST } }
    function success(artists, metadata) { return { type: artistsConstants.GET_TOP_ARTISTS_SUCCESS, artists, metadata } }
    function failure(error) { return { type: artistsConstants.GET_TOP_ARTISTS_FAILURE, error } }

}

function getMetadata(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.id);
        artistsService.getMetadata(ids)
            .then(
                metadata => {
                    const data = artistsFormatter.formatMetadata(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_REQUEST } }
    function success(artists, total, metadata) { return { type: artistsConstants.GET_ARTISTS_SUCCESS, artists, total, metadata } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_FAILURE, error } }

}


function getTimeseriesArtists(params, useCache, filter) {
    return ( dispatch, getState ) => {
        if(getState().artists.timeseriesLoading)
            return;
        
        dispatch(request());
        const globalFilter = getState().filter.global;
        
        if(filter) {
            for(let entity of Object.keys(filter)) {
                params.filtered.push({
                    id: entity,
                    value: filter[entity]
                })
            };
        }
        
        return artistsService.getTimeseriesArtists(params, globalFilter)
            .then(
                artists => {
                    const metadata = getState().artists.metadata;
                    const data = artistsFormatter.formatStreamsStats(artists, metadata);
                    dispatch(success(data));
                    return artists;
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_REQUEST } }
    function success(artists) { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_SUCCESS, artists } }
    function failure(error) { return { type: artistsConstants.GET_TIMESERIES_ARTISTS_FAILURE, error } }
}

function getArtistsSocial(params, cached = false, mode) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const globalFilter = getState().filter.global,
            { socialItems, socialTotal } = getState().artists;
        let artistID = null;
        if(params.filtered && Array.isArray(params.filtered)) {
            let artist = find(params.filtered, filter=>filter.id == 'track_artists');
            if(artist && artist.value) {
                if(!Array.isArray(artist.value))
                    artist.value = [artist.value];
                
                artistID = artist.value;
            }
        }
        let getArtists;
        if(artistID) {
            getArtists = Promise.resolve({total:artistID.length, data: artistID.map(id => ({artist_id: id}))});
        }
        else {
            if(mode == 'inline') // 
                getArtists = Promise.resolve({total: 0, data: []});
            else {
                if(cached) 
                    getArtists = Promise.resolve({total: socialTotal, data: socialItems});
                else
                    getArtists = artistsService.getArtistsSocial(params, globalFilter);
            }
        }
         
        return getArtists.then(
                artists => {
                    //const data = artistsFormatter.formatForTable(artists);
                    //dispatch(success(data, artists.pages));
                    return dispatch(getMetadataSocial(artists));
                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_SOCIAL_REQUEST } }
    function success(artists, pages) { return { type: artistsConstants.GET_ARTISTS_SOCIAL_SUCCESS, artists, pages } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_SOCIAL_FAILURE, error } }
}

function getMetadataSocial(entities) {
    return ( dispatch, getState ) => {
        dispatch(request());
        const ids = entities.data.map(entity=>entity.artist_id);
        artistsService.getMetadata(ids, true)
            .then(
                metadata => {
                    const data = artistsFormatter.formatMetadataSocial(entities.data, metadata.data);
                    dispatch(success(data, entities.total, metadata.data));

                },
                error => {
                    dispatch(failure('error'))
                }
            );
    };

    function request() { return { type: artistsConstants.GET_ARTISTS_SOCIAL_REQUEST } }
    function success(artists, total, metadata) { return { type: artistsConstants.GET_ARTISTS_SOCIAL_SUCCESS, artists, total, metadata } }
    function failure(error) { return { type: artistsConstants.GET_ARTISTS_SOCIAL_FAILURE, error } }

}
