import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import Demographics from '../../components/pages/audience/Demographics';
//import Sources from '../../components/pages/audience/Sources';
//import Devices from '../../components/pages/audience/Devices';
import Sources from '../../components/common/Stats/Sources';
import Devices from '../../components/common/Stats/Devices';
import Territory from '../../components/pages/audience/Territory';
import RepeatListeners from '../../components/pages/audience/RepeatListeners';
import MultiArtistsFollowers from '../../components/pages/result/MultiArtistsFollowers';
import Streams from '../../components/pages/audience/Streams';
import TiktokTimeseries from '../../components/pages/result/TiktokTimeseries';

import {Widget, WithWidgetManager, HiddenElements} from '../../components/widgets/manager';

class ResultAudience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterInitialized: false
        }
    }
    
    
    render() {
        const { filter, flatFilter, widgetProps, sharedMode } = this.props;
        return <div className="inner-page">
            {!sharedMode && <div className="widget-row">
                <HiddenElements widgetProps={widgetProps} />
            </div>}                    
            {/*
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="followers" widgetProps={widgetProps}>
                    <MultiArtistsFollowers filter={filter} mode="followers" shadowChartProps={{id: 'audience_followers_line_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="monthly_listeners" widgetProps={widgetProps}>
                    <MultiArtistsFollowers filter={filter} mode="monthly_listeners" shadowChartProps={{id: 'audience_monthly_listeners_line_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            */}
            <div className="widget-row widget-row--full-width">
                <Widget widgetID="listeners" widgetProps={widgetProps}>
                    <Streams filter={flatFilter} shadowChartProps={{id: 'audience_streams_listeners', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="territories" widgetProps={widgetProps} className=" widget-with-single-select" >
                    <Territory filtered={flatFilter} expanded={true}   />
                </Widget>
                <Widget widgetID="repeat_listeners" widgetProps={widgetProps}>
                    <RepeatListeners filtered={flatFilter}  />
                </Widget>                
            </div>
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="demographics" widgetProps={widgetProps} className=" widget-with-single-select">
                    <Demographics filter={flatFilter} />
                </Widget>
                <Widget widgetID="sources" widgetProps={widgetProps} className=" widget-with-single-select">
                {/*<Sources filtered={flatFilter} discovery={false} />*/}
                    <Sources discovery={false} filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'audience_sources_pie_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>            
            <div className="widget-row widget-row--half-width">
                <Widget widgetID="sources_discovery" widgetProps={widgetProps} className=" widget-with-single-select">
                <Sources discovery={true} filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'audience_sources_discovery_pie_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
                <Widget widgetID="devices" widgetProps={widgetProps} className=" widget-with-single-select">
                <Devices filtered={filter} externalLinks={!sharedMode} shadowChartProps={{id: 'audience_devices_pie_chart', shadowChartWidth: 800, shadowChartHeight: 300}} />
                </Widget>
            </div>
        </div>            
    }
}

export default WithWidgetManager(ResultAudience, 'result_audience')